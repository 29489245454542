<template>
  <div class="login">
    <div class="main">
      <div class="title">
        <icon-font
          class="mr-2"
          name="#mdd-fubianLOGO"
          :width="36"
          :height="36"
          color="#2B7FE2"
        ></icon-font>
        <p>复变医疗·全膝关节置换术软组织平衡辅助决策系统</p>
      </div>
      <div class="loginBox">
        <!-- <img v-for="item in 4" :key="item"  :src="`@/assets/img/xgj/box${item}.png`" :alt="item"> -->
        <img  src="@/assets/img/xgj/box1.png" alt="1">
        <img  src="@/assets/img/xgj/box2.png" alt="2">
        <img  src="@/assets/img/xgj/box3.png" alt="3">
        <img  src="@/assets/img/xgj/box4.png" alt="4">
        <div class="tips">账号登录</div>
        <el-form class="login-box" :model="form" :rules="rules" ref="form">
          <div>
            <el-form-item prop="username" label="账号" class="user">
              <el-input v-model="form.username" placeholder="请输入账号"></el-input>
            </el-form-item>
            <el-form-item prop="password" label="密码" >
              <el-input type="password" v-model="form.password" placeholder="请输入密码" @keyup.enter.native="login()"></el-input>
            </el-form-item>
            <p class="login-tips">忘记密码？请联系复变超管</p>
          </div>
          <el-button style="width:100%;margin-top:20px" type="primary" size="normal" @click="login()">登&nbsp;录</el-button>
      </el-form>
      </div>
    </div>
    <div class="bottom">
      <img src="@/assets/img/xgj/loginBottom.png" alt="bg">
      <span>© 2020 苏州复变医疗科技有限公司. 保留所有权 | 苏ICP备20011208号-1</span>
    </div>
  </div>
</template>

<script>
import { login } from '@/api/modules/knee-system'
export default {
  name: 'login',
  data () {
    const validateName = (rule, value, callback) => {
      const reg = /^[a-zA-Z]\w{5,30}$/
      if (value === '') {
        this.isName = true
        callback(new Error(' '))
      } else if (!reg.test(value)) {
        this.isReg = true
        callback(new Error(' '))
      } else {
        this.isName = false
        this.isReg = false
        callback()
      }
    }
    const validateInput = (rule, value, callback) => {
      const name = this.isName
      const reg = this.isReg
      const reg1 = /^[\x21-\x7E]{6,20}$/
      if (name) {
        callback(new Error('请输入账号'))
      } else if (value === '') {
        callback(new Error('请输入密码'))
      } else if (!reg1.test(value) || reg) {
        callback(new Error('账号或密码错误'))
      } else {
        callback()
      }
    }
    return {
      form: {
        username: '',
        password: ''
      },
      isName: false,
      isReg: false,
      rules: {
        username: [{ validator: validateName, trigger: 'blur' }],
        password: [{ validator: validateInput, trigger: 'blur' }]
      }
    }
  },
  methods: {
    login () {
      this.$refs.form.validate((valid) => {
        // console.log(valid)
        if (valid) {
          login(this.form).then(res => {
            // console.log(res)
            if (res.code === 0) {
              this.$message({
                message: '登录成功',
                type: 'success',
                duration: 1000
              })
              setTimeout(() => {
                window.location.href = '/knee-system?#/index'
              }, 1000)
            } else {
              this.$message.error(res.error)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.el-form-item__label{
  font-size: 14px;
  color: rgba(255,255,255,0.88);
  height: 14px;
  line-height: 14px;
  margin-bottom: 10px;
}
/deep/.el-input__inner{
  height: 40px;
  border: 1px solid rgba(255,255,255,0.38);
  opacity: 1;
  border-radius: 2px;
  background: transparent;
  line-height: 40px;
  padding: 0 5px;
  &::placeholder {
      color: rgba(255,255,255,0.24);
      font-size: 14px;
    }
}
/deep/.el-input__inner:focus{border-color:#2B7FE2}
/deep/.el-input__inner:hover{border-color:#2B7FE2}
.login{
  width: 100%;
  height: 100vh;
  position: relative;
  background: #181A20;
  min-width: 600px;
}
/deep/.el-form-item__error{
  left: 0;
  top: 100px;
}
// /deep/.user .el-form-item__error{
//   left: 0;
//   top: 190px;
// }
.main{
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}
.title {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  p{
    height: 24px;
    font-size: 24px;
    color: #fff;
    margin-left: 8px;
    font-weight: normal;
  }
}
.loginBox{
  width: 646px;
  min-height: 452px;
  margin-top: 32px;
  background: #203247;
  position: relative;
  border-radius: 4px;
  color: #fff;
  padding: 56px 134px;
  .login-box{
    margin-top: 36px;
  }
  .tips{
    font-size: 24px;
    color: #fff;
    opacity: 0.88;
    text-align: center;
    font-weight: 500;
    height: 24px;
    line-height: 24px;
  }
  .login-tips{
    color: #58A1F6;
    font-size: 14px;
    text-align: right;
    height: 20px;
    line-height: 24px;
    margin-top: -10px;
  }
  img{
    width: 50px;
    height: 50px;
    position: absolute;
  }
  img:nth-child(1) {
    left: 0;
    top: 0;
  }
  img:nth-child(2) {
    right: 0;
    top: 0;
  }
  img:nth-child(3) {
    left: 0;
    bottom: 0;
  }
  img:nth-child(4) {
    right: 0;
    bottom: 0;
  }
}
.bottom{
  width: 100%;
  height: 195px;
  position: absolute;
  left: 0;
  bottom: 0;
  img{
    width: 100%;
    height: 100%;
  }
  span{
    display: inline-block;
    position: relative;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    color: #FFFFFF;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    opacity: 0.6;
  }
}
@media screen and (max-width: 800px) {
  .loginBox {
    width: 550px;
    padding: 40px 80px;
  }
}
</style>
